// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-apps-js": () => import("../src/pages/apps.js" /* webpackChunkName: "component---src-pages-apps-js" */),
  "component---src-pages-contact-js": () => import("../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kinderliedjes-veel-gestelde-vragen-js": () => import("../src/pages/kinderliedjes-veel-gestelde-vragen.js" /* webpackChunkName: "component---src-pages-kinderliedjes-veel-gestelde-vragen-js" */)
}

